.footerWrapper {
  width: 100%;

  .back {
    width: 22px;
    height: 22px;
    margin-left: 10px;
    margin-bottom: 12px;
  }

  .top {
    float: right;

    width: 22px;
    height: 22px;
    margin-right: 13px;
    margin-bottom: 10px;

    transform: rotate(90deg);

    cursor: pointer;
  }
}
