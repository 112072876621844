@font-face {
  font-family: 'Outfit';
  src: url('Outfit-ExtraBold.woff2') format('woff2'),
    url('Outfit-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('Outfit-Medium.woff2') format('woff2'),
    url('Outfit-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('Outfit-Bold.woff2') format('woff2'),
    url('Outfit-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('Outfit-Regular.woff2') format('woff2'),
    url('Outfit-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('Outfit-SemiBold.woff2') format('woff2'),
    url('Outfit-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('Outfit-Black.woff2') format('woff2'),
    url('Outfit-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('Outfit-ExtraLight.woff2') format('woff2'),
    url('Outfit-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('Outfit-Thin.woff2') format('woff2'),
    url('Outfit-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('Outfit-Light.woff2') format('woff2'),
    url('Outfit-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
