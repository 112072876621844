.wrapper {
  width: 100%;

  .welcome-wrapper {
    height: 100vh;

    text-align: center;

    margin-left: 5%;
    margin-right: 5%;

    display: grid;
    align-items: center;

    & p.heading {
      font-family: Outfit;
      font-size: xx-large;
      font-weight: bold;
    }

    & p.subheading {
      font-family: Outfit;
      font-size: large;
      font-weight: bolder;
    }

    img {
      width: 100px;
      height: 100px;
    }
  }

  .layout-wrapper {
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      z-index: 0;
      max-height: 90vh;
      max-width: 95vw;
    }

    .a-button {
      z-index: 2;
      position: absolute;
    }
  }
}
