.headerWrapper {
  position: absolute;
  top: 0;
  width: 100%;

  .back {
    width: 22px;
    height: 22px;
    margin-left: 10px;
    margin-top: 12px;

    cursor: pointer;
  }

  .title {
    float: right;

    width: 104px;
    height: 33px;
    margin-right: 13px;
    margin-top: 10px;

    font-family: Outfit;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: right;

    color: #000000;
  }
}
