.wave {
  width: 207px;
  height: 1790px;
}

.waveWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.card1 {
  position: absolute;
  left: 0;
  right: 0;
  top: 325px;

  display: flex;
  justify-content: center;
}

.card2 {
  position: absolute;
  left: 0;
  right: 0;
  top: 1215px;

  display: flex;
  justify-content: center;
}
