.fullViewport {
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
}

.subjectWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.symbol {
  width: 50px;
  height: 50px;
  cursor: pointer;

  position: absolute;
}

.draggable {
  touch-action: none;
  user-select: none;
}

.contentWrapper {
  position: absolute;
  top: 0;
  overflow: hidden;

  backdrop-filter: blur(20px);

  .text {
    margin-top: 46px;
    margin-left: 25px;
    margin-right: 25px;

    overflow-y: scroll;
  }
}

.cardWrapper {
  position: absolute;
  z-index: 10;
}

.videoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
