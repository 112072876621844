/* .cardText {
  font-size: xx-large;
  font-family: Outfit;
  font-weight: bolder;
  width: fit-content;
}

.cardWrapper {
  border-radius: 15px;
  background-color: rgba(0, 0, 255, 0.55);
  padding: 3%;
  width: fit-content;
}

.cardImage {
  border-radius: 15px;
  width: 10rem;
  margin: 0;
} */

@media only screen and (min-width: 390px) {
  .cardWrapper {
    width: 204px;
    height: 239px;

    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;

    .cardImageContainer {
      .cardImage {
        width: 135px;
        height: 141px;

        margin-top: 17px;
        margin-left: 35px;
      }
    }

    .cardText {
      width: 148px;
      height: 41px;

      margin-top: 29px;
      margin-left: 28px;

      font-family: Outfit;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
    }
  }
}
